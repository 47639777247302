<template>
    <div class="coursehour_content">
        <div class="coursehour_content_children">
            <div class="coursehour_header">
                <div class="coursehour_header_left">
                    <video src="../assets/leaning/video2.mp4" controls class="coursehour_video"></video>
                </div>
                <div class="coursehour_header_right">
                    <div class="coursehour_header_right_title">
                        店铺装修操作教程（手机端）
                    </div>
                    <div class="coursehour_header_right_teacher">
                        <div>讲师：梯到学习中心</div>
                        <div style="color: #9CA7B6;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2222人观看</div>
                    </div>
                    <div class="coursehour_header_right_desc">
                        <div>
                            <span style="color: #9CA7B6;">发布时间</span> &nbsp;&nbsp;&nbsp;&nbsp;2023-1-2
                        </div>
                        <div>
                            <span style="color: #9CA7B6;">课程标签</span> &nbsp;&nbsp;&nbsp;&nbsp;视觉销售能力，德努该死的不是的话
                        </div>
                        <div> <span style="color: #9CA7B6;">课程摘要</span> &nbsp;&nbsp;&nbsp;&nbsp; 手机端店铺装修的操作路径和具体流程</div>
                    </div>
                    <div class="coursehour_header_right_course">
                        <div><img src="../assets/leaning/xin.png" alt="" /><span>&nbsp;关注课程</span> </div>
                        <div style="margin-left: 3em;">
                            <img src="../assets/leaning/share.png" alt="" /> <span>&nbsp;分享课程</span>
                        </div>
                    </div>
                </div>

            </div>
            <div style=" margin-top: 3em;" class="satisfaction">
                <el-radio-group v-model="satisfaction" class="ml-4" >
                    <el-radio label="1" size="large"><img src="../assets/leaning/differ.png" alt="">&nbsp;&nbsp;非常不满意</el-radio>
                    <el-radio label="2" size="large"><img src="../assets/leaning/letterdiffer.png" alt="">&nbsp;&nbsp;不满意</el-radio>
                    <el-radio label="3" size="large"><img src="../assets/leaning/nomal.png" alt="">&nbsp;&nbsp;一般</el-radio>
                    <el-radio label="4" size="large"><img src="../assets/leaning/good.png" alt="">&nbsp;&nbsp;满意</el-radio>
                    <el-radio label="5" size="large"><img src="../assets/leaning/verygood.png" alt="">&nbsp;&nbsp;非常满意</el-radio>
                </el-radio-group>
            </div>
            <div class="course_introduction">
                <div class="course_introduction_left">
                    <div class="course_introduction_header">
                        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
                            <el-tab-pane label="课程简介" name="first">
                                <div class="introduction_text">
                                    本课程适用于京东小店商家，帮助没有美工资源、不会设计店铺的商家朋友们通过手机端京麦app-店铺装修功能，快速搭建精美的店铺首页，提升店铺形象，助力店铺销售转化。
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="课程评价" name="second">
                                <div style="box-sizing: border-box; ">
                                    <el-input type="textarea" :rows="6" v-model="comment">

                                    </el-input>
                                </div>
                                <div
                                    style="display: flex; justify-content: end; align-items: center;font-size: 1.2em;padding-right: 10px;margin-top: 1em;">
                                    0/15&nbsp;&nbsp;&nbsp;<el-button type="primary"> 评论</el-button>
                                </div>
                                <div class="commit_cont" v-for="item in 6" style="margin-top: 3em;">
                                    <div class="commit_cont_img">
                                        <img src="../assets/wechat_login.png" alt="">
                                    </div>
                                    <div style="line-height: 25px; padding-left: 20px;">
                                        <div style="color: #999;">张***非</div>
                                        <div>哈哈哈哈</div>
                                        <div style="color: #999;">10分钟前</div>

                                    </div>
                                </div>
                            </el-tab-pane>

                        </el-tabs>
                    </div>
                </div>
                <div class="course_introduction_right">
                    <div style="font-size: 1.3em; padding-bottom: 20px;">
                        相关课程
                    </div>
                    <div class="about_course" v-for="item in 3">
                        <div class="about_course_header">
                        </div>
                        <div class="about_course_title">电批啊实打实啊实打实的，氨基酸大苏打</div>
                        <div class="course_author">
                            <div>免费</div>
                            <div>
                                <img src="../assets/wechat_login.png" alt=""
                                    style="width: 20px; height: 20px; border-radius: 50%;" />
                                程普
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup >
import { ref, reactive } from 'vue'
const videosrc = ref("/src/assets/leaning/video2.mp4")

const comment = ref("")
const activeName = ref('first')

const handleClick = (tab, event) => {
    console.log(tab, event)
}
const satisfaction = ref("1")
</script>

<style scoped lang="scss">
.coursehour_content {
    min-height: 1600px;
    width: 100%;
    display: flex;
    justify-content: center;

    .coursehour_content_children {
        width: 70%;
        min-height: 1600px;

        // border: 1px solid gainsboro;
        .satisfaction {
            width: 100%;
            height: 5em;
            background-color: #f1f1f1;
            display: flex;
            align-items: center;
            :deep(.ml-4){
                display: flex;
                align-items: center;
            }
            img{
                vertical-align: middle;
            }
        }

        .coursehour_header {
            display: flex;
            min-height: 40em;
            margin-top: 30px;

            .coursehour_header_left {
                flex: 3;

                // border: 1px solid gainsboro;
                .coursehour_video {
                    width: 100%;
                    height: 40em;
                }
            }

            .coursehour_header_right {
                flex: 2;
                padding-left: 3em;

                .coursehour_header_right_title {
                    font-size: 1.8em;
                    font-weight: 600;
                }

                .coursehour_header_right_teacher {
                    margin-top: 3em;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid gainsboro;
                }

                .coursehour_header_right_desc {
                    height: 8em;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    border-bottom: 1px solid gainsboro;
                    line-height: 20px;
                }

                .coursehour_header_right_course {
                    display: flex;
                    align-items: center;
                    height: 8em;

                    img {
                        width: 20px;
                        height: 20px;
                        object-fit: contain;
                        vertical-align: middle;
                        cursor: pointer;
                    }
                }

                // border: 1px solid black;
            }
        }

        .course_introduction {
            display: flex;
            margin-top: 20px;

            .course_introduction_left {
                flex: 7;
                min-height: 50em;
                background-color: white;

                .course_introduction_header {
                    padding-left: 10px;

                    .introduction_text {
                        padding-top: 30px;
                        font-size: 1.2em;
                        color: #303030;
                        line-height: 25px;
                    }

                    .commit_cont {
                        display: flex;
                        height: 8em;

                        box-sizing: border-box;
                        border-bottom: 1px solid #f2f2f2;

                        .commit_cont_img {
                            width: 30px;
                            height: 30px;

                            img {
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                            }

                        }
                    }
                }
            }

            .course_introduction_right {
                flex: 2;
                padding: 10px;

                .about_course {
                    width: 200px;
                    height: 188px;
                    margin-bottom: 10px;

                    .about_course_header {
                        width: 100%;
                        height: 120px;
                        background: url('../assets/leaning/video1.png') no-repeat;
                        background-size: 100% 100%;
                    }

                    .about_course_title {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        word-break: break-all;
                        font-size: 1.2em;
                        padding: 5px;
                    }

                    .course_author {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0px 5px;

                        img {
                            vertical-align: middle;
                        }
                    }
                }

                .about_course:hover {
                    box-shadow: 5px 5px 10px gainsboro;
                    cursor: pointer;
                }
            }
        }
    }
}</style>